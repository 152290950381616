import React, { useEffect, useState } from 'react'
import clienteAxios from '../../config/clienteAxios'
import './admin.css'

const url = 'https://backend-innpulsatec.lantiasas.com/api/'; /* https://vtsas.co/diagnosis/api/ *///TODO corregir para que funcione con el .env
// const url = 'http://127.0.0.1:8000/api/';

const Admin = () => {

    const [companies, setCompanies] = useState('');
    const [companySelected, setCompanySelected] = useState('');
    console.log('companies', companies);

    useEffect(() => {
        allCompanies();
    }, [])

    const allCompanies = async () => {
        
        try {
            let response = await clienteAxios.get('companiesinnpulsa');
            console.log('response', response.data);
            setCompanies(response.data);

        } catch (error) {

        }
    }

    const selectCompany = async (e) => {
        console.log(e.target.value);
        try {
            let response = await clienteAxios.get(`companyById/${e.target.value}`);
            console.log('response', response.data);
            setCompanySelected(response.data[0])
        } catch (error) {

        }
    }

    const download = async() => {
        try {
            let response = await clienteAxios.get(`allCompanies`);
            console.log('response', response);
            let xlsx = require('json-as-xlsx')
            let settings = {
                fileName: 'empresasInscritas', // Name of the spreadsheet
                extraLength: 5, // A bigger number means that columns will be wider
                writeOptions: {} // Style options from https://github.com/SheetJS/sheetjs#writing-options
            }

            let data = [
                {
                    sheet: 'reporte',
                    columns: [
                        { label: 'Nombre de la compañia',              value: 'companyName'},
                        { label: 'Nit de la compañia',                 value: 'nit'        },
                        { label: 'Digito de verificación',             value: 'nitDigito'  },
                        { label: 'Fecha constitución',                 value: 'constitucion'},
                        { label: 'Tamaño',                             value: 'companySize'},
                        { label: 'Número de empleados',                value: 'employee_number'},
                        { label: 'Fecha de inscripción',               value: 'inscription_date'},
                        { label: 'Departamento',                       value: 'departamento'},
                        { label: 'Municipio',                          value: 'municipio'},
                        { label: 'Barrio',                             value: 'company_neighborhood'},
                        { label: 'Dirección',                          value: 'company_address'},
                        { label: 'Total de ingresos',                  value: 'assets_at_the_last_period_2021'},
                        { label: 'Ánimo de lucro',                     value: 'profit_motive'},
                        { label: 'Página web',                         value: 'web_page'},
                        { label: 'Bienes o servicios ofertados',       value: 'services_offered'},
                        { label: 'Sector CIUU',                        value: 'sector'},
                        { label: 'Proceso de liquidación',             value: 'liquidacion'},
                        { label: 'Censo minminas',                     value: 'censo_minminas'},
                        { label: 'Empresa spinoff',                    value: 'spinoff'},
                        { label: 'Universidad asociada',               value: 'university_spinoff'},
                        { label: 'Nombre representante legal',         value: 'legal_representative_name'},
                        { label: 'Doc identidad Rep legal',            value: 'legal_representative_document'},
                        { label: 'Cargo Rep legal',                    value: 'legal_representative_position'},
                        { label: 'Celular Rep legal',                  value: 'legal_representative_phone'},
                        { label: 'Email Rep legal',                    value: 'legal_representative_email'},
                        { label: 'Confirmación email Rep legal',       value: 'legal_representative_email_confirm'},
                        { label: 'Género Rep legal',                   value: 'legal_representative_gender'},
                        { label: 'Incluido en RUV',                    value: 'legal_representative_ruv'},
                        { label: 'Nombre primer contacto',             value: 'contacto'},
                        { label: 'Doc identidad primer contacto',      value: 'contact1_document'},
                        { label: 'Cargo primer contacto',              value: 'cargo'},
                        { label: 'Email primer contacto',              value: 'email'},
                        { label: 'Confirmación email primer contacto', value: 'emailConfirmation'},
                        { label: 'Celular primer contacto',            value: 'contactNumber'},
                        { label: 'Nombre segundo contacto',            value: 'contact2'},
                        { label: 'Doc identidad segundo contacto',     value: 'contact2_document'},
                        { label: 'Cargo segundo contacto',             value: 'cargo2'},
                        { label: 'Email segundo contacto',             value: 'correo2'},
                        { label: 'Confirmación email segundo contacto',value: 'correo2'},
                        { label: 'Celular segundo contacto',           value: 'number_contact2'},
                    ],
                    content: response.data
                }
            ]

            xlsx(data, settings)

        } catch (error) {

        }



    }

    return (
        <div className='adminMain'>
            <div className='adminBody'>
                {companies !== '' &&
                    <div className='controlPanel'>
                        <div>
                            <label style={{ marginRight: '10px' }}> <strong>Elegir empresa:</strong></label>
                            <select style={{ marginTop: '30px' }} onChange={selectCompany}>
                                <option value="">--------------</option>
                                {companies.map(item => {
                                    return <option value={item.id}>{item.companyName}</option>
                                })}
                            </select>
                        </div>
                        <div>
                            <label style={{ marginTop: '30px', marginLeft: '20px' }}> <strong>Empresas registradas:</strong></label>
                            <span style={{ color: 'red', marginLeft: '10px' }}> <strong>{companies.length}</strong></span>
                        </div>
                        <div>
                            <div onClick={download} className='report'>Descargar reporte</div>
                        </div>
                    </div>

                }
                <br /><hr /><hr /><br />
                <div className='contentBody'>
                     <div className='row'>
                        <div className='col-12 col-md-6 col-lg-6 d-flex justify-content-end mb-3'>
                            <b>Fecha de postulación: </b>
                        </div>
                        <div className='col-12 col-md-6 col-lg-6 d-flex justify-content-start'>
                            {companySelected.inscription_date}
                        </div>
                    </div>
                    <br />
                    <div className='row  col-12'>
                        <div className='col-12 col-md-6 col-lg-6 d-flex justify-content-center mb-3'>
                            <b> Empresa: </b>
                            <div className='col-12 col-md-6 col-lg-6 d-flex mb-3'>
                                {companySelected.companyName}
                            </div>
                        </div>
                        <div className='col-12 col-md-6 col-lg-6 d-flex justify-content-center mb-3'>
                            <b>Nit: </b>
                            <div className='col-12 col-md-6 col-lg-6 d-flex  mb-3'>
                                {companySelected.nit} - {companySelected.nitDigito}
                            </div>
                        </div>
                        <div className='col-12 col-md-6 col-lg-6 d-flex justify-content-center mb-3'>
                                <b>Constitución: </b>
                            <div className='col-12 col-md-6 col-lg-6 d-flex  mb-3'>
                                {companySelected.constitucion}
                            </div>
                        </div>
                        <div className='col-12 col-md-6 col-lg-6 d-flex justify-content-center mb-3'>
                                <b>Tamaño de la compañía: </b>
                            <div className='col-12 col-md-6 col-lg-6 d-flex mb-3'>
                                {companySelected.companySize}
                            </div>
                        </div>
                        <div className='col-12 col-md-6 col-lg-6 d-flex justify-content-center mb-3'>
                                <b>Sector: </b>
                            <div className='col-12 col-md-6 col-lg-6 d-flex mb-3'>
                                {companySelected.sector}
                            </div>
                        </div>
                        <div className='col-12 col-md-6 col-lg-6 d-flex justify-content-center mb-3'>
                                <b>Departamento: </b>
                            <div className='col-12 col-md-6 col-lg-6 d-flex mb-3'>
                                {companySelected.departamento}
                            </div>
                        </div>
                        <div className='col-12 col-md-6 col-lg-6 d-flex justify-content-center mb-3'>
                                <b>Municipio: </b>
                            <div className='col-12 col-md-6 col-lg-6 d-flex mb-3'>
                                {companySelected.municipio}
                            </div>
                        </div>
                        <div className='col-12 col-md-6 col-lg-6 d-flex justify-content-center text-center mb-3'>
                                <b>¿Se encuentra incursa en procesos de liquidación?: </b>
                            <div className='col-12 col-md-6 col-lg-6 d-flex mb-3'>
                                {companySelected.liquidacion}
                            </div>
                        </div>
                    </div>
                    <br /><hr /><hr /><br />
                    <div className='row  col-12'>
                        <div className='col-12 col-md-6 col-lg-6 d-flex justify-content-center mb-3'>
                                <b>Nombre del representante legal:</b>
                            <div className='col-12 col-md-6 col-lg-6 d-flex mb-3'>
                                {companySelected.legal_representative_name}
                            </div>
                        </div>
                        <div className='col-12 col-md-6 col-lg-6 d-flex justify-content-center mb-3'>
                                <b>Cargo del representante legal:</b>
                            <div className='col-12 col-md-6 col-lg-6 d-flex mb-3'>
                                {companySelected.legal_representative_position}
                            </div>
                        </div>
                        <div className='col-12 col-md-6 col-lg-6 d-flex justify-content-center mb-3'>
                                <b>Celular del representante legal:</b>
                            <div className='col-12 col-md-6 col-lg-6 d-flex mb-3'>
                                {companySelected.legal_representative_phone}
                            </div>
                        </div>
                        <div className='col-12 col-md-6 col-lg-6 d-flex justify-content-center mb-3'>
                                <b>Correo del representante legal:</b>
                            <div className='col-12 col-md-6 col-lg-6 d-flex mb-3'>
                                {companySelected.legal_representative_email}
                            </div>
                        </div>
                    </div>
                    <br /><hr /><hr /><br />
                    <div className='row  col-12'>
                        <div className='col-12 col-md-6 col-lg-6 d-flex justify-content-center mb-3'>
                                <b>Nombre del primer contacto:</b>
                            <div className='col-12 col-md-6 col-lg-6 d-flex mb-3'>
                                {companySelected.contacto}
                            </div>
                        </div>
                        <div className='col-12 col-md-6 col-lg-6 d-flex justify-content-center mb-3'>
                                <b>Cargo del primer contacto:</b>
                            <div className='col-12 col-md-6 col-lg-6 d-flex mb-3'>
                                {companySelected.cargo}
                            </div>
                        </div>
                        <div className='col-12 col-md-6 col-lg-6 d-flex justify-content-center mb-3'>
                                <b>Celular del primer contacto: :</b>
                            <div className='col-12 col-md-6 col-lg-6 d-flex mb-3'>
                                {companySelected.contactNumber}
                            </div>
                        </div>
                        <div className='col-12 col-md-6 col-lg-6 d-flex justify-content-center mb-3'>
                                <b>Correo del primer contacto:</b>
                            <div className='col-12 col-md-6 col-lg-6 d-flex mb-3'>
                                {companySelected.email}
                            </div>
                        </div>
                    </div>
                    <br /><hr /><hr /><br />
                    <div className='row  col-12'>
                        <div className='col-12 col-md-6 col-lg-6 d-flex justify-content-center mb-3'>
                                <b>Nombre del segundo contacto:</b>
                            <div className='col-12 col-md-6 col-lg-6 d-flex mb-3'>
                                {companySelected.contact2}
                            </div>
                        </div>
                        <div className='col-12 col-md-6 col-lg-6 d-flex justify-content-center mb-3'>
                                <b>Cargo del segundo contacto:</b>
                            <div className='col-12 col-md-6 col-lg-6 d-flex mb-3'>
                                {companySelected.cargo2}
                            </div>
                        </div>
                        <div className='col-12 col-md-6 col-lg-6 d-flex justify-content-center mb-3'>
                                <b>Celular del segundo contacto: :</b>
                            <div className='col-12 col-md-6 col-lg-6 d-flex mb-3'>
                                {companySelected.number_contact2}
                            </div>
                        </div>
                        <div className='col-12 col-md-6 col-lg-6 d-flex justify-content-center mb-3'>
                                <b>Correo del segundo contacto:</b>
                            <div className='col-12 col-md-6 col-lg-6 d-flex mb-3'>
                                {companySelected.correo2}
                            </div>
                        </div>
                    </div>
                    <br /><hr /><hr /><br />

                    <div className='d-flex justify-content-center'>
                        {companySelected.hasOwnProperty('camara_comercio') &&
                            companySelected.camara_comercio !== null &&
                            <span>
                                <a className="downloadButton" href={`${url}download/${companySelected.camara_comercio.substr(5, companySelected.camara_comercio.length)}`} download>
                                    <button type="button" className='btn btn-primary'>Certificado de existencia y representación legal</button>
                                </a>
                            </span>
                        }
                        {companySelected.hasOwnProperty('rut') &&
                            companySelected.rut !== null &&
                            <span className='mx-3'>
                                <a className="downloadButton" href={`${url}download/${companySelected.rut.substr(5, companySelected.rut.length)}`} download>
                                    <button type="button" className='btn btn-primary'>RUT</button>
                                </a>
                            </span>
                        }
                        {companySelected.hasOwnProperty('cedula') &&
                            companySelected.cedula !== null &&
                            <span>
                                <a className="downloadButton" href={`${url}download/${companySelected.cedula.substr(5, companySelected.cedula.length)}`} download>
                                    <button type="button" className='btn btn-primary'>Carta de postulación</button>
                                </a>
                            </span>
                        }
                        {companySelected.hasOwnProperty('estadoFinanciero') &&
                            companySelected.estadoFinanciero !== null &&
                            <span className='mx-3'>
                                <a className="downloadButton" href={`${url}download/${companySelected.estadoFinanciero.substr(5, companySelected.estadoFinanciero.length)}`} download>
                                    <button type="button" className='btn btn-primary'>Estado financiero</button>
                                </a>
                            </span>
                        }
                    </div>
                    <br /><hr /><hr /><br />
                </div>
            </div>
        </div>
    )
}

export default Admin

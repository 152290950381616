import React, { useEffect, useState } from 'react'
import axios from 'axios';
import clienteAxios from '../../config/clienteAxios'
import './inscription.scss'

const Inscription = () => {

    const [formValues, setFormValues] = useState('');
    // console.log('values', formValues);
    const [cedula, setCedula] = useState('')
    const [rut, setRut] = useState('')
    const [camaraComercio, setCamaraComercio] = useState('')
    const [estadoFinanciero, setEstadoFinanciero] = useState('')
    const [error, setError] = useState('');
    const [sending, setSending] = useState(false);
    const [sent, setSent] = useState(false);
    const [departments, setDepartments] = useState([]);
    const [municipalities, setMunicipalities] = useState([]);

    useEffect(() => {
        allDepartments();
    }, [])

    const allDepartments = async () => {
        try {
            await clienteAxios.get('allDepartments').then(response => {
                setDepartments(response.data);
            });
        } catch (error) { }
    }

    const fecha = new Date();
    const nueva_fecha = ((fecha.getFullYear() - 3) + '-' + ('0' + (fecha.getMonth() + 1)).slice(-2) + '-' + ('0' + fecha.getDate()).slice(-2))

    const handleInputChange = (event) => {
        event.preventDefault();
        console.log(event.target.name, event.target.value);

        if (event.target.name === 'departamento') {
            let selectMunicipio = document.getElementById('municipio');
            selectMunicipio.removeAttribute('disabled');
            clienteAxios.get('allMunicipalities/' + event.target.value).then(response => {
                setMunicipalities(response.data);
            });
        }
        if(event.target.name === 'nit'){
            if(event.target.value !== ''){
            let universitySpinoff = document.getElementById('nitDigito');
            universitySpinoff.disabled = false;
	    universitySpinoff.required = true;
            }
            if(event.target.value === ''){
                let universitySpinoff = document.getElementById('nitDigito');
            universitySpinoff.disabled = true;
            universitySpinoff.required = false;
            }
        }

        if(event.target.name === 'spinoff'){
            if(event.target.value === 'Sí'){
                let universitySpinoff = document.getElementById('university_spinoff');
                universitySpinoff.disabled = false;
            }else{
                let universitySpinoff = document.getElementById('university_spinoff');
                universitySpinoff.disabled = true;
		universitySpinoff.required = true;

            }
        }

        if (event.target.name === 'nit') {
            clienteAxios.get('validateCompany/' + event.target.value).then(response => {
                let nitEmpresa = document.getElementById('id_nit');
                let nitEmpresaMensaje = document.getElementById('id_nitFeedback');
                if (response.data[0]) {
                    nitEmpresa.setAttribute('class', 'form-control is-invalid');
                    nitEmpresaMensaje.innerHTML = 'La empresa ' + response.data[0]['companyName'] + ' con NIT ' + response.data[0]['nit'] + ' ya se encuentra registrada';
                    document.getElementById('btnEnviar').disabled = true;
                } else {
                    nitEmpresa.removeAttribute('class', 'is-invalid');
                    nitEmpresa.setAttribute('class', 'form-control');
                    document.getElementById('btnEnviar').disabled = false;
                }
            })
        }

        setFormValues({
            ...formValues,
            [event.target.name]: event.target.value
        })
    }


    const onchange = (e) => {
        console.log('e.target.files[0]', e.target.files[0]);

        if (e.target.files[0].type === 'application/pdf') {
            if (e.target.name === 'cedulaFile') {
                setCedula(e.target.files[0])
            }
            else if (e.target.name === 'rut') {
                setRut(e.target.files[0])
            }
            else if (e.target.name === 'camaraComercio') {
                setCamaraComercio(e.target.files[0])
            }
            else if (e.target.name === 'estadoFinanciero') {
                setEstadoFinanciero(e.target.files[0])
            }

            return;
        }

        alert('El formato de archivo debe ser .pdf')
    }

    const sendForm = async (event) => {
        event.preventDefault();
        console.log('formvalue', formValues);
        setSending(true);

        let notificaciones = document.getElementById('notificaciones');
        /* notificaciones.innerHTML = '<div class="alert alert-success" role="alert">Validando datos...</div>'; *///Descomentar


        let internalError = '';
        if (formValues.companyName === '' || formValues.companyName === undefined) {
            internalError = 'El nombre de la empresa es requerido, ';
        }
        if (formValues.nit === '' || formValues.nit === undefined) {
            internalError = 'El nit es requerido, ';
        }
        if (formValues.nitDigito === '' || formValues.nitDigito === undefined) {
            internalError = 'El digito del nit es requerido, ';
        }
        if (formValues.constitucion === '' || formValues.constitucion === undefined) {
            internalError = 'La fecha de constitución de la empresa es requerido, ';
        }
        if (formValues.companySize === '' || formValues.companySize === undefined) {
            internalError = 'El tamaño de la compañia es requerido, ';
        }
        if (formValues.contacto === '' || formValues.contacto === undefined) {
            internalError = 'El nombre del primer contacto es requerido, ';
        }
        if (formValues.contact1_document === '' || formValues.contact1_document === undefined) {
            internalError = 'El documento del primer contacto es requerido, ';
        } 
        if (formValues.cargo === '' || formValues.cargo === undefined) {
            internalError = 'El cargo del primer contacto es requerido ';
        }
        if (formValues.sector === '' || formValues.sector === undefined) {
            internalError = 'El sector es requerido';
        }
        if (formValues.email === '' || formValues.email === undefined) {
            internalError = 'El correo del primer contacto es requerido, ';
        }
        if (formValues.emailConfirmation === '' || formValues.emailConfirmation === undefined) {
            internalError = 'La confirmación de correo del primer contacto es requerida, ';
        }
        if (formValues.contactNumber === '' || formValues.contactNumber === undefined) {
            internalError = 'El número celular del primer contacto es requerido, ';
        }
        if (formValues.liquidacion === '' || formValues.liquidacion === undefined) {
            internalError = 'La liquidación es requerido, ';
        }
        if (formValues.censo_minminas === '' || formValues.censo_minminas === undefined) {
            internalError = 'El campo de censo MINMINAS es requerido, ';
        }
        if (formValues.spinoff === '' || formValues.spinoff === undefined) {
            internalError = 'El campo de empresa spinoff es requerido, ';
        }
        // if ((formValues.university_spinoff === '' || formValues.university_spinoff === undefined) && formValues.spinoff !== '') {
        // internalError = 'La universidad spinoff es requerido, ';
        // }
        if (formValues.legal_representative_name === '' || formValues.legal_representative_name === undefined) {
            internalError = 'El nombre del representante legal es requerido, ';
        }
        if (formValues.legal_representative_document === '' || formValues.legal_representative_document === undefined) {
            internalError = 'El numero de documento de identidad del representante legal es requerido, ';
        }
        if (formValues.legal_representative_position === '' || formValues.legal_representative_position === undefined) {
            internalError = 'El cargo del representante legal es requerido, ';
        }
        if (formValues.legal_representative_phone === '' || formValues.legal_representative_phone === undefined) {
            internalError = 'El número celular del representante legal es requerido, ';
        }
        if (formValues.legal_representative_email === '' || formValues.legal_representative_email === undefined) {
            internalError = 'El correo del representante legal es requerido, ';
        }
        if (formValues.legal_representative_email_confirm === '' || formValues.legal_representative_email_confirm === undefined) {
            internalError = 'La confirmación de correo del representante legal es requerida, ';
        }
        if (formValues.legal_representative_gender === '' || formValues.legal_representative_gender === undefined) {
            internalError = 'El género del representante legal es requerido, ';
        }
        if (formValues.legal_representative_ruv === '' || formValues.legal_representative_ruv === undefined) {
            internalError = 'El RUV es requerida, ';
        }
        if (rut === '') {
            internalError = 'Debes adjuntar el rut, ';
        }
        if (cedula === '') {
            internalError = 'Debes adjuntar la cédula, ';
        }
        if (camaraComercio === '') {
            internalError = 'Debes adjuntar la cámara de comercio, ';
        }
        if (formValues.contact2 === '' || formValues.contact2 === undefined || !formValues.contact2) {
            internalError = 'El nombre del segundo contacto es requerido, ';
        }
        if (formValues.contact2_document === '' || formValues.contact2_document === undefined || !formValues.contact2_document) {
            internalError = 'El numero de documento del segundo contacto es requerido, ';
        }
        if (formValues.cargo2 === '' || formValues.cargo2 === undefined || !formValues.cargo2) {
            internalError = 'El cargo del segundo contacto es requerido, ';
        }
        if (formValues.number_contact2 === '' || formValues.number_contact2 === undefined || !formValues.number_contact2) {
            internalError = 'El número celular del segundo contacto es requerido, ';
        }
        if (formValues.correo2 === '' || formValues.correo2 === undefined || !formValues.correo2) {
            internalError = 'El correo del segundo contacto es requerido, ';
        }
        if (formValues.emailConfirmation2 === '' || formValues.emailConfirmation2 === undefined || !formValues.emailConfirmation2) {
            internalError = 'El campo de confirmación del segundo contacto es requerido, ';
        }
        if (formValues.departamento === '' || formValues.departamento === undefined || !formValues.departamento) {
            internalError = 'El departamento es requerido, ';
        }
        if (formValues.municipio === '' || formValues.municipio === undefined || !formValues.municipio) {
            internalError = 'El municipio es requerido, ';
        }
        if (formValues.company_address === '' || formValues.company_address === undefined || !formValues.company_address) {
            internalError = 'La dirección de la empresa es requerida , ';
        }
        if (formValues.employee_number === '' || formValues.employee_number === undefined || !formValues.employee_number) {
            internalError = 'La número de empleados es requerido , ';
        }
        if (formValues.assets_at_the_last_period_2021 === '' || formValues.assets_at_the_last_period_2021 === undefined || !formValues.assets_at_the_last_period_2021) {
            internalError = 'El total de activos es requerido , ';
        }
        if (formValues.profit_motive === '' || formValues.profit_motive === undefined || !formValues.profit_motive) {
            internalError = 'El campo animo de lucro es requerido , ';
        }
        // if (formValues.web_page === '' || formValues.web_page === undefined || !formValues.web_page) { //ESTE CAMPO NO ES OBLIGATORIO
        //     internalError = 'La pagina web es requerida, ';
        // }
        // services_offered
        if (formValues.services_offered === '' || formValues.services_offered === undefined || !formValues.services_offered) {
            internalError = 'El campo bienes o servicios ofertados es requerido , ';
        }
        if (estadoFinanciero === '') {
            internalError = 'Debes adjuntar el estado financiero, ';
        }
        if (formValues.termcondVt === '' || formValues.termcondVt === undefined || !formValues.termcondVt) {
            internalError = 'Debes aceptar terminos y condiciones de VTSAS, ';
        }
        if (formValues.termcond === '' || formValues.termcond === undefined || !formValues.termcond) {
            internalError = 'Debes aceptar terminos y condiciones, ';
        }
        if (internalError === '') {
            setError('');
            console.log('formValuesssss', formValues);
            

             if ((formValues.email !== formValues.emailConfirmation) || (formValues.correo2 !== formValues.emailConfirmation2) || (formValues.legal_representative_email !== formValues.legal_representative_email_confirm)) {
                 alert('Alguno de los correos no coincide en su confirmación')
                 setSending(false)
                 return;
             }

            notificaciones.innerHTML = '<div class="alert alert-success" role="alert">Adjuntando archivos...</div>';
            formValues.companyName = formValues.companyName.toUpperCase();
            formValues.email = formValues.email.toLowerCase();
            formValues.correo2 = formValues.correo2.toLowerCase();
            const data = new FormData();
            
            data.append('companyName', formValues.companyName);
            data.append('nit', formValues.nit);
            data.append('nitDigito', formValues.nitDigito);
            data.append('constitucion', formValues.constitucion);
            data.append('companySize', formValues.companySize);
            data.append('employesNumber', formValues.employesNumber);
            data.append('contacto', formValues.contacto);
            data.append('contact1_document', formValues.contact1_document);
            data.append('cargo', formValues.cargo);
            data.append('sector', formValues.sector);
            data.append('email', formValues.email);
            data.append('emailConfirmation', formValues.emailConfirmation);
            data.append('contactNumber', formValues.contactNumber);
            data.append('liquidacion', formValues.liquidacion);
            data.append('censo_minminas', formValues.censo_minminas);
            data.append('spinoff', formValues.spinoff);
            data.append('university_spinoff', formValues.university_spinoff);
            data.append('legal_representative_name', formValues.legal_representative_name);
            data.append('legal_representative_document', formValues.legal_representative_document);
            data.append('legal_representative_position', formValues.legal_representative_position);
            data.append('legal_representative_phone', formValues.legal_representative_phone);
            data.append('legal_representative_email', formValues.legal_representative_email);
            data.append('legal_representative_email_confirm', formValues.legal_representative_email_confirm);
            data.append('legal_representative_gender', formValues.legal_representative_gender);
            data.append('legal_representative_ruv', formValues.legal_representative_ruv);
            data.append('rut', rut);
            data.append('cedula', cedula);
            data.append('camaraComercio', camaraComercio);
            data.append('contact2', formValues.contact2);
            data.append('contact2_document', formValues.contact2_document);
            data.append('cargo2', formValues.cargo2);
            data.append('number_contact2', formValues.number_contact2);
            data.append('correo2', formValues.correo2);
            data.append('departamento', formValues.departamento);
            data.append('municipio', formValues.municipio);
            data.append('company_neighborhood', formValues.company_neighborhood);
            data.append('company_address', formValues.company_address);
            data.append('employee_number', formValues.employee_number);
            data.append('assets_at_the_last_period_2021', formValues.assets_at_the_last_period_2021);
            data.append('profit_motive', formValues.profit_motive);
            data.append('web_page', formValues.web_page);
            data.append('services_offered', formValues.services_offered);
            data.append('estadoFinanciero', estadoFinanciero);



            let responseFiles = await axios.post(`https://backend-innpulsatec.lantiasas.com/api/sendfiles`, data); /* https://vtsas.co/diagnosis/api/sendfiles *///TODO Cambiar en producción o adecuar en local
            notificaciones.innerHTML = '<div class="alert alert-success" role="alert">Archivos guardados, enviando datos...</div>';
            formValues.id = responseFiles.data.id
            // let response = await axios.post(`https://vtsas.co/diagnosis/api/inscription`, formValues);
            notificaciones.innerHTML = '<div class="alert alert-success" role="alert">Datos guardados</div>';
            // console.log('response saveAnswers', response);//TODO Revisar solo si queda fallando
             if (!responseFiles.data.hasOwnProperty('error')) {
                 alert('Respuestas enviadas');
                 setSending(false);
                 setSent(true)
             }

        } else {
            setError(internalError);
        }
    }

    return (
        <div className='mainInscription'>
            <div className="formularioOut">
                <div className="formulario">
                    <div className='d-flex justify-content-center mb-2'>
                        <div className='d-flex justify-content-center col-12'>
                            <a href="#formInnpulsa"><img src={require("../../../src/components/landing/imgs/21-Innpulsa-Tec-Banner-v7-noviembre.jpg")} className='col-12 col-xl-10 bannerInnpulsa' alt="Banner Innpulsa Tec VTSAS" /></a>
                            {/* <img src={require("../../../src/components/landing/imgs/21-Innpulsa-Tec-Banner-v-junio.jpg")} className='col-12 col-xl-10 bannerInnpulsa' alt="Banner Innpulsa Tec VTSAS" data-bs-toggle="modal" data-bs-target="#modalFormulario" /> */}
                        </div>
                    </div>
                    <div className='d-flex justify-content-center mb-2'>
                        <div className='d-flex justify-content-center col-12'>
                            <div className='cardInnpulsa col-md-5 col-xl-5'>
                                <h6>¿Cuál es el objetivo del programa INNPULSA TEC 2.0?</h6>
                                <div className="cardInnpulsaInfo">
                                    <center> Fortalecer la competitividad regional y nacional basada en ciencia, tecnología e innovación de 400 MIPYMES a partir de la realización de Bootcamps y sensibilización en Industria 4.0, sostenibilidad y economía circular.</center>
                                </div>
                            </div>
                            <div className='cardInnpulsa col-md-5 col-xl-5'>
                                <h6>¿Qué busca la convocatoria de INNPULSA TEC 2.0?</h6>
                                <div className="cardInnpulsaInfo">
                                    <center> Seleccionar 400 empresas para brindar formación y capacitación en los componentes de Tecnologías 4.0, Prototipado y Economía Circular, para fomentar los procesos de innovación empresarial en las Mipymes Colombianas.</center>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='d-flex justify-content-center mb-2'>
                        <div className='d-flex justify-content-center col-12 px-2'>
                            <div className='cardInnpulsa col-md-10 col-xl-10'>
                                <h6>¿Qué beneficios tiene INNPULSA TEC 2.0?</h6>
                                <div className="cardInnpulsaInfo">

                                    <center> <li> Plan de transferencia de conocimiento en temáticas como: tecnología de la cuarta revolución industrial, prototipado y economía circular, entre otros.</li></center><br />
                                    <center> <li> Váuchers de Conocimiento para 40 Mipymes beneficiadas.</li></center><br />
                                    <center> <li> Váuchers de Innovación hasta por 18 millones de pesos cada uno para 3 empresas beneficiadas.</li></center><br />
                                    <center> <li> Consolidar redes y relaciones entre los diferentes actores: ESEIs, sector empresarial y consultores especializados.</li></center><br />
                                    <center> <li> Identificar nuevos productos, servicios y modelos de negocio a partir de las necesidades específicas de cada Mipyme apoyadas en mentorías personalizadas.</li></center><br />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='d-grid gap-2'>
                        <p>Para mayor información: <a href="mailto:innpulsatec@vtsas.co" class="text-decoration-none">innpulsatec@vtsas.co</a></p>
                    </div>
                    <hr className='col-11' />
                    <div className='justify-content-center mb-2'>
                        {!sent ?
                            <form onSubmit={sendForm} id='formInnpulsa'><div className='row container px-5'>
                                <h3 className='titleForm justify-content-center mb-2'>PROGRAMA INNPULSA TEC 2.0</h3>
                                <div className='intro'>
                                    <strong>Requisitos para la postulación:</strong>
                                    <div>- Debe diligenciar todos los campos.</div>
                                    <div>- Tener al menos 3 años de constitución al momento de la postulación.</div>
                                    <div>- Ser micro, pequeña o mediana empresa, de acuerdo con lo establecido por el Decreto 957 de 2019.</div>
                                    <div>- Estados financieros comparativos 2020-2021 y/o certificado de ventas año 2021</div>
                                    <div>- Cumplir con los requerimientos indicados en la carta de postulación (Anexo 1)</div>
                                    <div>- Pertenecer a cualquiera de los 32 departamentos, con excepción de las empresas de Bogotá D.C.</div>
                                    <div>- Certificado de existencia y representación legal con renovación al año <b>2022</b>. La fecha de expedición del certificado no deberá ser mayor a 30 días a la fecha de postulación.</div>
                                </div>
                                <div className='col-12'><hr /><h4>Información de la empresa</h4></div>
                                <div className="col-12 col-md-6 col-lg-6 field">
                                    <label htmlFor="">1. Nombre o razón social de la empresa</label>
                                    <input onChange={handleInputChange} name="companyName" type="text" className="form-control" maxlength={100} placeholder='Digite el nombre de la empresa' required />
                                </div>
                                <div className="col-12 col-md-6 col-lg-6 field has-validation">
                                    <label htmlFor="">2. Número de NIT (sin dígito de verificación)</label>
                                    <input onChange={handleInputChange} name="nit" type="text" id='id_nit' minLength={9} maxlength={9} pattern="\d{9}" className="form-control" placeholder='Digite el NIT de la empresa' required/>
                                    <div id="id_nitFeedback" class="invalid-feedback"></div>
                                </div>
                                <div className="col-12 col-md-6 col-lg-6 field">
                                    <label htmlFor="">3. Dígito de verificación NIT </label>
                                    <label htmlFor=""></label>
                                    <select onChange={handleInputChange} name="nitDigito" className="form-control" id="nitDigito" required disabled>
                                        <option value=''>Seleccione el dígito de verificación NIT</option>
                                        <option>0</option><option>1</option><option>2</option><option>3</option><option>4</option><option>5</option><option>6</option><option>7</option><option>8</option><option>9</option>
                                    </select>
                                </div>
                                <div className="col-12 col-md-6 col-lg-6 field">
                                    <label htmlFor="">4. Fecha de constitución de la empresa (Registro de Cámara de Comercio)</label>
                                    <input onChange={handleInputChange} name="constitucion" type="date" className="form-control" max={nueva_fecha} required />
                                </div>
                                <div className="col-12 col-md-6 col-lg-6 field">
                                    <label htmlFor="">5. Tamaño de la empresa
                                        según lo establecido en el Decreto 957 de 2019</label>
                                    <select onChange={handleInputChange} name="companySize" className="form-control" id="" required>
                                        <option value=''>Seleccione el tamaño de la empresa</option>
                                        <option>Micro</option>
                                        <option>Pequeña</option>
                                        <option>Mediana</option>
                                    </select>
                                </div>
                                <div className="col-12 col-md-6 col-lg-6 field">
                                    <label htmlFor="">6. Departamento</label>
                                    <label htmlFor=""></label>
                                    <select onChange={handleInputChange} name="departamento" id="departamento" className="form-control" required>
                                        <option value=''>Seleccione un departamento</option>
                                        {departments.map(item => {
                                            return <option key={item.id.toString()} value={item.name}>{item.name}</option>
                                        })}
                                    </select>
                                </div>
                                <div className="col-12 col-md-6 col-lg-6 field">
                                    <label htmlFor="">7. Municipio</label>
                                    <select onChange={handleInputChange} name="municipio" id="municipio" className="form-control" disabled required>
                                        <option value=''>Seleccione un municipio</option>
                                        {municipalities.map(item => {
                                            return <option key={item.id.toString()} value={item.name}>{item.name}</option>
                                        })}
                                    </select>
                                </div>
                                <div className="col-12 col-md-6 col-lg-6 field">
                                    <label htmlFor="">8. Barrio del beneficiario (empresa)</label>
                                    <input onChange={handleInputChange} name="company_neighborhood" type="text" className="form-control" maxlength={49} placeholder='Digite el barrio de la empresa' required />
                                </div>
                                <div className="col-12 col-md-6 col-lg-6 field">
                                    <label htmlFor="">9. Dirección del beneficiario (empresa)</label>
                                    <input onChange={handleInputChange} name="company_address" type="text" className="form-control" maxlength={49}  placeholder='Digite la dirección de la empresa' required />
                                </div>
                                <div className="col-12 col-md-6 col-lg-6 field">
                                    <label htmlFor="">11. No. empleados de la empresa</label>
                                    <input onChange={handleInputChange} name="employee_number" type="number" className="form-control" placeholder='Digite solamente números' required />
                                </div>
                                <div className="col-12 col-md-6 col-lg-6 field">
                                    <label htmlFor="">12. Valor total de los activos a último periodo de la empresa 2021 (en pesos colombianos)</label>
                                    <input onChange={handleInputChange} name="assets_at_the_last_period_2021" type="number" className="form-control" placeholder='Digite el total de activos' required />
                                </div>
                                <div className="col-12 col-md-6 col-lg-6 field">
                                    <label htmlFor="">13. ¿Empresa con ánimo de lucro?</label>
                                    <label htmlFor=""></label>
                                    <select onChange={handleInputChange} name="profit_motive" id="" className="form-control" required>
                                        <option value=''>Seleccione una opción</option>
                                        <option>Sí</option>
                                        <option>No</option>
                                    </select>
                                </div>
                                <div className="col-12 field">
                                    <label htmlFor="">14. Página web de la empresa (en caso de contar con página)</label>
                                    <input onChange={handleInputChange} name="web_page" type="url" className="form-control" placeholder='Digite el enlace de la página web' />
                                </div>
                                <div className="col-12 field">
                                    <label htmlFor="">15. Bienes o servicios ofertados</label>
                                    <textarea onChange={handleInputChange} name="services_offered" id="" cols="30" rows="3" maxLength={500}  placeholder='Digite los bienes o servicios de la empresa &nbsp;(máximo 500 caracteres)' required></textarea>
                                </div>                              
                                <div className="col-12 col-md-6 col-lg-6 field">
                                    <label htmlFor="">16. Sector al que pertenece. <br />
                                        Escriba el código CIIU (Solo los 4 números)</label>
                                    <input onChange={handleInputChange} name="sector" type="text" className="form-control" maxlength={4} pattern="\d{4}" placeholder='Digite el código CIIU' required />
                                    
                                </div>
                                <div className="col-12 col-md-6 col-lg-6 field">
                                    <label htmlFor="">17. ¿Se encuentra incursa en procesos de <br /> liquidación?</label>
                                    <select onChange={handleInputChange} name="liquidacion" id="" className="form-control" required>
                                        <option value=''>Seleccione una opción</option>
                                        <option>Sí</option>
                                        <option>No</option>
                                    </select>
                                </div>
                                 <div className="col-12 col-md-6 col-lg-6 field">
                                    <label htmlFor="">18.  ¿La empresa está incluida en el censo <br /> MINMINAS?</label>
                                    <select onChange={handleInputChange} name="censo_minminas" id="" className="form-control" required>
                                        <option value=''>Seleccione una opción</option>
                                        <option>Sí</option>
                                        <option>No</option>
                                    </select>
                                </div>
                                <div className="col-12 col-md-6 col-lg-6 field">
                                    <label htmlFor="">19. ¿La empresa es una spinoff?</label>
                                    <label htmlFor=""></label>
                                    <select onChange={handleInputChange} name="spinoff" id="" className="form-control" required>
                                        <option value=''>Seleccione una opción</option>
                                        <option>Sí</option>
                                        <option>No</option>
                                    </select>
                                </div>
                                <div className="col-12 field">
                                    <label htmlFor="">20. Universidad a la que se encuentra asociada (solo si es una spinoff)</label>
                                    <input onChange={handleInputChange} name="university_spinoff" id='university_spinoff' type="text" className="form-control" placeholder='Digite la universidad' disabled />
                                </div>
                                <div className='col-12'><hr /><h4>Información del representante legal</h4></div>
                                <div className="col-12 col-md-6 col-lg-6 field">
                                    <label htmlFor="">21. Nombre del representante legal</label>
                                    <input onChange={handleInputChange} name="legal_representative_name" type="text" className="form-control" maxLength={100} placeholder='Digite el nombre del representante legal' required />
                                </div>
                                <div className="col-12 col-md-6 col-lg-6 field">
                                    <label htmlFor="">22. Número de documento de identidad</label>
                                    <input onChange={handleInputChange} name="legal_representative_document" type="number" className="form-control" placeholder='Digite el número de documento' required />
                                </div>
                                <div className="col-12 col-md-6 col-lg-6 field">
                                    <label htmlFor="">23. Cargo del representante legal</label>
                                    <input onChange={handleInputChange} name="legal_representative_position" type="text" className="form-control" maxLength={100} placeholder='Digite el cargo del representante legal' required />
                                </div>
                                <div className="col-12 col-md-6 col-lg-6 field">
                                    <label htmlFor="">24. Número celular del representante legal</label>
                                    <input onChange={handleInputChange} name="legal_representative_phone" type="number" className="form-control" placeholder='Digite el número de celular' required />
                                </div>
                                <div className="col-12 col-md-6 col-lg-6 field">
                                    <label htmlFor="">25. Correo electrónico del representante legal</label>
                                    <input onChange={handleInputChange} name="legal_representative_email" id='legal_representative_email' type="email" maxlength={70} className="form-control" placeholder='Digite el correo del representante legal' required />
                                </div>
                                <div className="col-12 col-md-6 col-lg-6 field">
                                    <label htmlFor="">26. Confirmar correo electrónico del representante legal</label>
                                    <input onChange={handleInputChange} name="legal_representative_email_confirm" id='legal_representative_email_confirm' type="email" maxlength={70} className="form-control" placeholder='Digite de nuevo el correo electronico' required />
                                </div>
                                <div className="col-12 col-md-6 col-lg-6 field">
                                    <label htmlFor="">27. Género del representante legal</label>
                                    <label htmlFor=""></label>
                                    <select onChange={handleInputChange} name="legal_representative_gender" id="" className="form-control" required>
                                        <option value=''>Seleccione una opción</option>
                                        <option>Femenino</option>
                                        <option>Masculino</option>
                                        <option>No Binario</option>
                                        <option>Otro</option>
                                    </select>
                                </div>
                                <div className="col-12 col-md-6 col-lg-6 field">
                                    <label htmlFor="">28. ¿El representante legal está incluido en el Registro Único de Victimas (RUV)?</label>
                                    <select onChange={handleInputChange} name="legal_representative_ruv" id="" className="form-control" required>
                                        <option value=''>Seleccione una opción</option>
                                        <option>Sí</option>
                                        <option>No</option>
                                    </select>
                                </div>
                                <div className='col-12'><hr /><h4>Información del primer contacto</h4></div>
                                <div className="col-12 col-md-6 col-lg-6 field">
                                    <label htmlFor="">29. Nombre de primer contacto</label>
                                    <input onChange={handleInputChange} name="contacto" type="text" maxlength={100} className="form-control" placeholder='Digite el nombre del primer contacto'required />
                                </div>
                                <div className="col-12 col-md-6 col-lg-6 field">
                                    <label htmlFor="">30. Número de documento de identidad</label>
                                    <input onChange={handleInputChange} name="contact1_document" type="number" className="form-control" placeholder='Digite el número de documento' required />
                                </div>
                                <div className="col-12 col-md-6 col-lg-6 field">
                                    <label htmlFor="">31. Cargo del primer contacto</label>
                                    <input onChange={handleInputChange} name="cargo" type="text" maxlength={100}  className="form-control" placeholder='Digite el cargo del primer contacto' required />
                                </div>
                                <div className="col-12 col-md-6 col-lg-6 field">
                                    <label htmlFor="">32. Número celular del primer contacto</label>
                                    <input onChange={handleInputChange} name="contactNumber" type="number" className="form-control" placeholder='Digite el número de celular' required />
                                </div>
                                <div className="col-12 col-md-6 col-lg-6 field">
                                    <label htmlFor="">33. Correo electrónico del primer contacto</label>
                                    <input onChange={handleInputChange} name="email" type="email" maxlength={70} className="form-control" placeholder='Digite el correo del primer usuario' required />
                                </div>
                                <div className="col-12 col-md-6 col-lg-6 field">
                                    <label htmlFor="">34. Confirmar correo electrónico</label>
                                    <input onChange={handleInputChange} name="emailConfirmation" type="email" maxlength={70} className="form-control" placeholder='Digite de nuevo el correo electronico' required />
                                </div>
                                <div className='col-12'><hr /><h4>Información del segundo contacto</h4></div>
                                <div className="col-12 col-md-6 col-lg-6 field">
                                    <label htmlFor="">35. Nombre del segundo contacto</label>
                                    <input onChange={handleInputChange} name="contact2" type="text" maxlength={100} className="form-control" placeholder='Digite el nombre del segundo contacto'  required />
                                </div>
                                <div className="col-12 col-md-6 col-lg-6 field">
                                    <label htmlFor="">36. Número de documento de identidad</label>
                                    <input onChange={handleInputChange} name="contact2_document" type="number" className="form-control" placeholder='Digite el número de documento' required />
                                </div>
                                <div className="col-12 col-md-6 col-lg-6 field">
                                    <label htmlFor="">37. Cargo del segundo contacto</label>
                                    <input onChange={handleInputChange} name="cargo2" type="text"  maxlength={100} className="form-control" placeholder='Digite el cargo del segundo contacto'required />
                                </div>
                                <div className="col-12 col-md-6 col-lg-6 field">
                                    <label htmlFor="">38. Número celular del segundo contacto</label>
                                    <input onChange={handleInputChange} name="number_contact2" type="number" className="form-control" placeholder='Digite el número de celular' required />
                                </div>
                                <div className="col-12 col-md-6 col-lg-6 field">
                                    <label htmlFor="">39. Correo electrónico del segundo contacto</label>
                                    <input onChange={handleInputChange} name="correo2" type="email" maxlength={70} className="form-control" placeholder='Digite el correo del segundo contacto' required />
                                </div>
                                <div className="col-12 col-md-6 col-lg-6 field">
                                    <label htmlFor="">40. Confirmar correo electrónico</label>
                                    <input onChange={handleInputChange} name="emailConfirmation2" type="email" maxlength={70} className="form-control" placeholder='Digite el correo del segundo contacto' required />
                                </div>
                                <hr />
                                <div className='col-12'><h4>Documentación requerida</h4></div>
                                <div className='row' style={{ marginTop: '10px' }}>
                                    <div className="form-group inputs col-12 col-md-12 col-lg-12 d-flex justify-content-center">
                                        <div className='button-formato'>
                                            <a className="downloadButton" href={`https://backend-innpulsatec.lantiasas.com/api/downloadAnexo/${'Anexo No. 1 - Carta de postulación.docx'}`} download>{/*//TODO cambiar en testing y producción */}
                                                <button type="button" className='btn btn-primary'>Descargar formato Anexo No. 1 - Carta de postulación</button>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="form-group inputs col-12 col-md-12 col-lg-12 d-flex justify-content-center">
                                        <div style={{ width: '300px', display: 'flex', justifyContent: 'center' }}>Adjuntar Anexo No. 1 - Carta de postulación</div>
                                        <div style={{ marginLeft: '20px' }}> <input type="file" accept="application/pdf" name="cedulaFile" id="files" onChange={onchange} className="form-control" required /></div>
                                    </div>
                                    <div className="form-group inputs col-12 col-md-12 col-lg-12 d-flex justify-content-center">
                                        <div style={{ width: '300px', display: 'flex', justifyContent: 'center' }}>Adjuntar RUT</div>
                                        <div style={{ marginLeft: '20px' }}> <input type="file" accept="application/pdf" name="rut" id="files" onChange={onchange} className="form-control" required /></div>
                                    </div>
                                    <div className="form-group inputs col-12 col-md-12 col-lg-12 d-flex justify-content-center">
                                        <div style={{ width: '300px', display: 'flex', justifyContent: 'center' }}>Adjuntar certificado de existencia y representación legal</div>
                                        <div style={{ marginLeft: '20px' }}> <input type="file" accept="application/pdf" name="camaraComercio" id="files" onChange={onchange} className="form-control" required /></div>
                                    </div>
                                    <div className="form-group inputs col-12 col-md-12 col-lg-12 d-flex justify-content-center">
                                        <div style={{ width: '300px', display: 'flex', justifyContent: 'center' }}>Adjuntar estado financiero comparativos 2020-2021 y/o certificado de ventas año 2021</div>
                                        <div style={{ marginLeft: '20px' }}> <input type="file" accept="application/pdf" name="estadoFinanciero" id="files" onChange={onchange} className="form-control" required /></div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-12 col-lg-12 field intro">

                                    <div className='agree'>
                                        <input onChange={(e) => handleInputChange(e)} style={{ width: '17px', marginTop: '7px' }} type="checkbox" name="termcondVt" id="" required />
                                        <strong style={{ width: '310px' }}>Acepto términos y condiciones de VTSAS </strong>
                                    </div>
                                    <p>
                                        Autorizo a VTSAS o a quien este designe, así como a iNNpulsa Colombia para la recolección, almacenamiento y uso de mis Datos Personales, con la finalidad de informarme sobre eventos y convocatorias organizados por estas entidades, que estén relacionados con nuestras
                                        funciones, los servicios que prestamos, las publicaciones que elaboramos y solicitar que se evalúe la calidad de los servicios. En virtud de la Ley 1581 de 2012, autorizo igualmente la recolección de datos biométrico, a través de cualquier canal, y que servirán
                                        únicamente para la validación de mi identidad y en ningún caso podrán circularse.
                                        VTSAS  garantiza que en todo momento permitirá a los titulares de datos personales ejercer su derecho para consultar, solicitar su corrección, actualización o supresión, de acuerdo con los lineamientos consignados en las Políticas de Protección de Datos Personales.
                                    </p>

                                    <div className='agree'>
                                        <input onChange={(e) => handleInputChange(e)} style={{ width: '17px', marginTop: '7px' }} type="checkbox" name="termcond" id="" required />
                                        <strong style={{ width: '235px' }}>Acepto términos y condiciones</strong>
                                    </div>
                                    <p>
                                        Autorizo a INNPULSA COLOMBIA y VTSAS en desarrollo de las actividades establecidas en la política pública artículo 13 de la ley 1753 de 2015 y del convenio interadministrativo No. 099-2021 para enviar información, de los instrumentos, invitaciones, capacitaciones, programas y eventos, u otras actividades que puedan fortalecer y potencializar mi quehacer y ayudar al logro de mis desafíos, y compartir con los aliados y proveedores mi información y almacenamiento en servidores en Colombia o en el exterior.
                                        Para conocer nuestra política de tratamiento de datos personales, puede consultarla en la página web <a href="https://www.innpulsacolombia.com" target={'blank'}>www.innpulsacolombia.com</a>. En el siguiente enlace <a href="https://www.innpulsacolombia.com/sites/default/files/Politica-de-proteccion-de-datos-personales.pdf" target={'blank'}>POLÍTICA DE TRATAMIENTO DE DATOS PERSONALES INNPULSA COLOMBIA</a> puede revisar las finalidades para el tratamiento de los datos.
                                    </p>
                                    {/* <p>
                            Para conocer nuestra política de tratamiento de datos personales, puede consultarla en la página web www.innpulsacolombia.com.
                            En el siguiente enlace <a href="https://www.innpulsacolombia.com/sites/default/files/2021-08/Finalidad%20de%20datos_1.pdf">https://www.innpulsacolombia.com/sites/default/files/2021-08/Finalidad%20de%20datos_1.pdf</a> puede revisar las
                            finalidades para el tratamiento de los datos.
                        </p> */}
                                </div>
                                <div className="col-12 col-md-12 col-lg-12 field sendButton d-flex justify-content-center">
                                    {!sending &&
                                        // <button id='btnEnviar' onClick={sendForm} className='send btn btn-danger col-4'>Enviar</button>//TODO Esto no se descomenta
                                        <button id='btnEnviar' type='submit' className='send btn btn-danger col-4'>Enviar</button>
                                    }
                                    {sending &&
                                        <button id='btnEnviando' className='send btn btn-danger col-4' disabled>Enviando, por favor espere...</button>
                                    }
                                </div>
                                <div id='notificaciones' className='d-flex justify-content-center'></div>
                                {error !== '' &&
                                    <div style={{ color: 'red' }}>{error}</div>
                                }
                            </div></form>
                            :
                            <div class="alert alert-success" role="alert">
                                ¡La información ha sido guardada en nuestra base de datos, gracias por diligenciar el formulario!
                            </div>
                        }
                    </div>
                </div>
            </div>
            </div>
    )
}

export default Inscription

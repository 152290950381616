import React, { useState } from 'react'
import { Redirect } from 'react-router-dom'
import cookie from 'react-cookies'
import axiosLogin from '../../config/axiosLogin'


const ProtectedRoute = ({ ...props }) => {
    //componente prop
    const Component = props.component;
    const [correctToken, setCorrectToken] = useState(false)

    const verifyToken = async () => {

        axiosLogin.defaults.headers.common['Authorization'] = 'Bearer ' + cookie.load('token');
        const response = await axiosLogin.post('login/token');

        if (response.data.hasOwnProperty('user')) {
            if(response.data.user.profiled > -1){
                setCorrectToken(true)
            }

        } else {
            console.log('el else');
            cookie.remove('token');
            return <Redirect to={{ pathname: '/' }} />
        }
    }

    if (!cookie.load('token')) {
        return <Redirect to={{ pathname: '/' }} />
    }
    else {
        if (correctToken) {
            return <Component {...props} />
        }
        verifyToken()
        return (<h1>Loading...</h1>)
    }
}

export default ProtectedRoute;
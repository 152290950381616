import axios from 'axios';

var clienteAxios = axios;
if(window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1" || window.location.hostname === ""){
    clienteAxios = axios.create({
	baseURL:'https://backend-innpulsatec.lantiasas.com/api/'
    });
}else{
    clienteAxios = axios.create({
        baseURL:'https://backend-innpulsatec.lantiasas.com/api/'
    });
}


export default clienteAxios;

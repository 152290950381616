import logo from './logo.svg';
import './App.css';
import Inscription from './components/inscription/Inscription';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Landing from './components/landing/Landing';
import Login from './components/login/Login';
import ProtectedRoute from './components/login/ProtectedRoute';
import Admin from './components/admin/Admin';


function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          {/* <Inscription /> */}
          <Route exact path="/" component={Inscription} />
          <Route exact path="/home" component={Landing} />
          <Route exact path="/login" component={Login} />
          <ProtectedRoute exact path="/admin" component={Admin} />
        </Switch>
        {/* <div style={{width:'100%', display:'flex', justifyContent:'end'}}>
          <img className='logos' src={require('../src/components/landing/imgs/footerImpulsa.png')} width="300" height="70" />
        </div> */}
      </Router>
    </div>
  );
}

export default App;

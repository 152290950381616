import React from 'react'
import './landing.css'

const Landing = () => {
    return (
        <div className='contentLanding'>
            <section className="background">
                <div className="row w-100">
                    <div className="col-lg-6 text-center mb-5 mt-5">
                        <p className="pwhite t-1 mt-5">PROGRAMA</p>
                        <p className="pyellow t-2"><b>INNPULSA TEC 2.0</b></p>
                        <div className="box-big">
                            <div className="box d-flex align-items-center">
                                <img src={require("./imgs/icon1.png")} alt="" />
                                <p className="pwhite">Lorem ipsum dolor sit amet cone mollitia nam?</p>
                            </div>
                            <div className="box d-flex align-items-center">
                                <img src={require("./imgs/icon2.png")} alt="" />
                                <p className="pwhite">Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae, impedit. Cum tempore temporibus similique quasi deserunt quod enim, reiciendis iste quo offichil? Totam, aut..</p>
                            </div>
                            <div className="box d-flex align-items-center">
                                <img src={require("./imgs/icon3.png")} alt="" />
                                <p className="pwhite">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Expedita delectus quidem architecto explicabo itaque placeat distinctio? Neque temporibus nemo v? Quia, sed totam.</p>
                            </div>
                        </div>
                        <div classNAme="dis-flex" style={{display:'flex', justifyContent:'center', marginTop:'20px', marginLeft:'30px' }}>
                            <div className="btn-div pt-1">
                                <a href="http://vtsas.co/plataforma_vt_v2/"><b>CLIC AQUÍ PARA INGRESAR A LA PLATAFORMA</b></a>
                            </div>
                        </div>

                    </div>
                    <div className="col-lg-6 dis-flex mt-5">
                        {/* <div class="box-video">
                 </div>
                </div> */}
                        <iframe width="550" height="345" src="https://www.youtube.com/embed/o9Aa1irBKH0">
                        </iframe>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Landing